
import Vue, { PropType } from 'vue';
import AnswerImages from '@/components/ui/AnswerImages';
import {
  AnswersComponentData,
  IAnswer, IAnswerChoice, IQuestion, IQuestionChoice, QuestionType,
} from '@/domains/multileveltest';
import { ITestSessionQuestionsGetResponseData } from '@/services/api/lxp-multi-level-test/types';

export default Vue.extend({
  name: 'MultileveltestAnswers',
  components: {
    AnswerImages,
  },
  inject: ['Names'],
  props: {
    hasTimeAndVisibleTimer: {
      type: Boolean,
      required: true,
    },
    test: {
      type: Object as PropType<ITestSessionQuestionsGetResponseData>,
      required: true,
    },
  },

  data(): AnswersComponentData {
    return {
      selectedMono: {} as IQuestionChoice,
      selectedPoly: [] as IQuestionChoice[],
    };
  },

  computed: {
    question(): IQuestion {
      return this.test.question;
    },

    questionChoices(): IQuestionChoice[] {
      return this.test.question.choices || [];
    },

    playerSessionId(): number {
      return Number(this.$route.params.playerSessionId);
    },

    answer(): IAnswer {
      return this.test.answer;
    },

    isMonoQuestion(): boolean {
      return this.question.questionType === QuestionType.MONO;
    },

    stepId(): string {
      return this.$route.params.stepId;
    },
  },

  watch: {
    'test.answer': {
      deep: true,
      async handler() {
        this.setSelectedAnswerChoices();
      },
    },
  },
  mounted() {
    this.setSelectedAnswerChoices();
  },

  methods: {
    /**
     * Обновляем состояние выбранных ответов
     */
    async changeAnswer(): Promise<void> {
      const answers: Record<string| number, boolean> = {};
      if (this.isMonoQuestion) {
        answers[this.selectedMono.id] = true;
      } else {
        this.selectedPoly.forEach((item: IQuestionChoice) => {
          answers[item.id] = true;
        });
      }
      this.$emit('update', answers);
    },

    setSelectedAnswerChoices(): void {
      this.clearSelectedItems();
      if (this.isMonoQuestion) this.setRadioChoice();
      if (!this.isMonoQuestion) this.setCheckboxChoices();
    },

    setRadioChoice(): void {
      const answerChoices = this.answer.choices;
      const questionChoices = this.question.choices;

      if (answerChoices) {
        questionChoices.forEach((qChoice: IQuestionChoice) => {
          if (answerChoices.find((aChoice: IAnswerChoice) => (aChoice.id === qChoice.id) && aChoice.value)) {
            this.selectedMono = qChoice;
          }
        });
      }
    },

    setCheckboxChoices(): void {
      const answerChoices = this.answer.choices;
      const questionChoices = this.question.choices;

      const selectedPoly: Array<IQuestionChoice> = [];

      if (answerChoices) {
        questionChoices.forEach((qChoice: IQuestionChoice) => {
          if (answerChoices.find((aChoice: IAnswerChoice) => (aChoice.id === qChoice.id) && aChoice.value)) {
            selectedPoly.push(qChoice);
          }
        });
      }
      this.selectedPoly = selectedPoly;
    },

    clearSelectedItems() {
      this.selectedPoly = [] as IQuestionChoice[];
      this.selectedMono = {} as IQuestionChoice;
    },
  },
});
