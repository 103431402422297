
import Vue from 'vue';
import DialogComponent from '@/components/ui/DialogComponent';
import { Banner } from '@/types/banner';

export default Vue.extend({
  name: 'InstrumentReviewHeader',
  components: {
    DialogComponent,
  },
  inject: ['Names'],
  props: {
    skillName: {
      type: String,
      default: '',
    },
    currentQuestion: {
      type: Number,
      default: 0,
    },
    totalQuestion: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    testProgress() {
      // Note: 100 - это проценты, -1 потому что на текущей вопрос еще нет ответа
      return Math.round(((this.currentQuestion - 1) * 100) / this.totalQuestion);
    },
  },
  methods: {
    async leave() {
      (this.$refs.closeDialog as Banner).open();
    },
    close(isClose: boolean) {
      if (!isClose) {
        (this.$refs.closeDialog as Banner).close();
      } else {
        this.$emit('multileveltest:finish');
      }
    },
  },
});
