
import Vue, { PropType } from 'vue';

import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import { UUID } from '@/domains/common';

export default Vue.extend({
  name: 'QuestionImages',
  props: {
    imageUuids: {
      type: Array as PropType<UUID[]>,
      default: () => [],
    },
    /**
     * В просмотре результатов картинки чуть меньше, если их больше 2х
     */
    isReview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    images(): string[] {
      return this.imageUuids.map((uuid) => getImageUrl({
        uuid,
        type: 'question',
        name: 'image',
        // Note: временно закоментировано, иначе картинка обрезается гошей
        // size: '1024x1024',
      }));
    },
    imagesCount(): number {
      return this.images.length ?? 0;
    },
    containerClass(): string {
      if (this.imagesCount >= 3) {
        return this.isReview ? 'more--small' : 'more';
      }
      if (this.imagesCount === 2) {
        return 'two';
      }
      return '';
    },
  },
});
