<template>
  <div>
    <slot name="header" />

    <VContainer
      v-if="!$store.state.loading"
      :class="{ 'pt-0' : $vuetify.breakpoint.smAndDown }"
    >
      <VRow
        :no-gutters="$vuetify.breakpoint.smAndDown"
      >
        <VCol
          cols="6"
          md="1"
        >
          <!-- left buttons -->
          <!-- Note: button should be wrapped:
            <VRow>
              <VCol>
                BUTTON HERE
              </VCol>
            </VRow>
          -->
          <slot name="left" />
        </VCol>

        <VCol
          cols="6"
          md="1"
          order-md="last"
        >
          <!-- right buttons -->
          <!-- Note: button should be wrapped:
            <VRow>
              <VCol>
                BUTTON HERE
              </VCol>
            </VRow>
          -->

          <slot name="right" />
        </VCol>

        <VCol
          v-if="offset"
          md="10"
          cols="12"
        >
          <!-- center content -->
          <!-- Note: content should be wrapped:
            <VRow>
              <VCol>
                CONTENT HERE
              </VCol>
            </VRow>
          -->

          <VRow no-gutters>
            <VCol
              :cols="$vuetify.breakpoint.smAndUp ? 10 : 12"
              :offset="$vuetify.breakpoint.smAndUp ? 1 : 0"
            >
              <slot />
            </VCol>
          </VRow>
        </VCol>

        <VCol
          v-else
          md="10"
          cols="12"
        >
          <!-- center content -->
          <!-- Note: content should be wrapped:
            <VRow>
              <VCol>
                CONTENT HERE
              </VCol>
            </VRow>
          -->

          <slot />
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
export default {
  name: 'TestView',

  props: {
    offset: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
